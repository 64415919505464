import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Okarabällchen",
  "preis": "1,00-2,00 €"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hmmm, einer der besten Snacks der Welt.Frisch; Golgelb ausgebacken, im Salatblatt mit leckerer Sauce. Unschlagbar. `}</p>
    <p>{`Aber auch nach ein paar Nächten im Kühlschrank, machen unsere Okarabällchen noch eine hervorragende Figur. Saftig und energiereich sind sie perfekt als Snack für zwischendurch.
Das klappt kalt und wieder aufgewärmt.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      